import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="front-end--organizer-register"
export default class extends Controller {
  connect() {

    $('.field-incryption').validate({
      rules: {
        "code": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "code": {
          required: 'Please enter password.',
        }
      },
      submitHandler: function(form, event) {
        event.preventDefault();

        if (!$(form).data('submitted')) {
          $(form).data('submitted', true);

          if (typeof Turbo !== 'undefined') {
            Turbo.navigator.submitForm(form);
          } else {
            form.submit();
          }

          setTimeout(() => {
            let modalElement = $(form).closest('.modal');
            let modalInstance = bootstrap.Modal.getInstance(modalElement[0]);
            if (modalInstance) {
              modalInstance.hide();
            }
          }, 500);
        }
      }
    });

    $('#organizer_register_form').validate({
      rules: {
        "first_name": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "last_name": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 25
        },
        "company_name": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 50
        },
        "email": {
          required: true,
          emailregex: true
        },
        "contact_number": {
          phoneregex: true,
          minlength: 10,
          maxlength: 10
        },
        "address": {
          minlength: 2,
          maxlength: 150
        },
        "organization_size": {
          required: true
        },
        "country": {
          required: true
        }
      },
      messages: {
        "first_name": {
          required: 'First name is required',
          nameregex: 'First name contains alphabets only',
          minlength: 'First name must be 2-25 characters long',
          maxlength: 'First name must be 2-25 characters long'
        },
        "last_name": {
          required: 'Last name is required',
          nameregex: 'Last name contains alphabets only',
          minlength: 'Last name must be 3-25 characters long',
          maxlength: 'Last name must be 3-25 characters long'
        },
        "company_name": {
          required: 'Company name is required',
          nameregex: 'Company name contains alphabets only',
        },
        "contact_number": {
          phoneregex: "Please enter valid contact number",
          maxlength: "Contact number must be 10 digits long"
        },
        "email": {
          required: 'Email is required',
          emailregex: 'Please enter a valid email address'
        },
        "address": {
          minlength: 'Address must be 2-150 characters long',
          maxlength: 'Address must be 2-150 characters long'
        },
        "organization_size": {
          required: 'Organization size is required'
        },
        "country": {
          required: 'Country is required'
        }
      }
    });

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z]+(?:\s[a-zA-Z]+)*(?:\s?)$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /^[0-9]+$/.test(value);
    },'Contains digits only');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z0-9]+(?:[._%+()-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/g.test(value);
    });

    $.validator.addMethod('passwordmatch', function(value, element){
      var password = $("[name='password']").val()
      return this.optional(element) || password == value;
    });

    $.validator.addMethod('userpasswordmatch', function(value, element){
      var password = $("[name='user[password]']").val()
      return this.optional(element) || password == value;
    });

    $.validator.addMethod('passwordregex', function(value, element){
      return this.optional(element) || /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).+$/.test(value);
    },'Password must contains one uppercase, lowercase, number and special character');

  }
}
