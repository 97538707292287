import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="card-logs"
export default class extends Controller {
  connect() {
    initDateRangePicker("#card-log-date")
    $(".filter-btn").click(function(){
      console.log('on click')
      $(".card_log").toggleClass("order-enable");
    });
    $(".filter-btn").click(function(){
      console.log('on click')
      $(".employee-card-log").toggleClass("order-enable");
    });
    console.log('in cardlog ')
    customSelect2('#card_log_employee_id', true, 'All', true);
    $("#from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: '+0d',
    }).on('changeDate input', function (selected) {
      if (!$(this).val()) {
        $('#to_date').datepicker('setStartDate', null);
      } else if (selected.date != undefined) {
        var minDate = new Date(selected.date.valueOf());
        $('#to_date').datepicker('setStartDate', minDate);
      }
      })

    $("#to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: '+0d',
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#from_date').datepicker('setEndDate', new Date());
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#from_date').datepicker('setEndDate', maxDate);
        }
      });

    var initialFromDate = $('#from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#from_date').datepicker('setEndDate', initialToDate);
    }
    $(".sidesubmenu").on({
      mouseenter: function () {
        $(this).addClass("opne_menu");
      },
      mouseleave: function () {
        $(this).removeClass("opne_menu");
      },
    });
  }
}
